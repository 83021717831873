export async function createAuthUser (email, email_verified, phone_number, password, display_name, photo_url, disabled) {

    /*
		email='user@example.com',
		email_verified=False,
		phone_number='+15555550100',
		password='secretPassword',
		display_name='John Doe',
		photo_url='http://www.example.com/12345678/photo.png',
        disabled=False
    */
        
    try{
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");

        let raw = JSON.stringify({"email" : email, "email_verified" : email_verified, "phone_number": null, "password" : password, "display_name" : display_name, "photo_url" : photo_url, "disabled" : false  });

        let requestOptions = {
            method: 'POST',
            body: raw,
            headers: myHeaders,
            redirect: 'follow'
        };
      
        let response = await fetch("https://mls-grid-2.azurewebsites.net/auth-users", requestOptions);
        return response.json();
    } catch(error) {
        console.log('error', error);
    }
}