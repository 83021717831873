import React, { useContext }  from 'react'
import { Typography, Paper, Avatar, Button } from '@material-ui/core'
import VerifiedUserOutlined from '@material-ui/icons/VerifiedUserOutlined'
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter, Link } from 'react-router-dom'
import * as ROUTES from '../../constants/routes'
import * as ROLES from '../../constants/roles'
import { FirebaseContext } from '../Firebase';
import { useAuthorization  } from '../Session'
import { AuthContext } from '../../Auth.js';

const styles = theme => ({
	main: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
			width: 400,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginTop: theme.spacing.unit * 8,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
	},
	avatar: {
		margin: theme.spacing.unit,
		backgroundColor: theme.palette.secondary.main,
	},
	submit: {
		marginTop: theme.spacing.unit * 3,
	},
})

const HomePage = props => {
	const firebase = useContext(FirebaseContext);
  const { currentUser } = useContext(AuthContext);
  const condition = currentUser => !!currentUser;
  const authorization = useAuthorization(condition,props);
  const { classes } = props;
	  
  const logout = () => {
    firebase.doSignOut();
  }

  return (	
    <AuthContext.Consumer>
    { currentUser => currentUser.currentUser  ? 
		<main className={classes.main}>
        {/*<div>{console.log(currentUser.currentUser.roles.ADMIN)}</div>*/}
        <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
            <VerifiedUserOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
            Hello { currentUser.currentUser ? currentUser.currentUser.name : 'Guest!' }
        </Typography>
        { currentUser.currentUser.roles.ADMIN ?	          
        <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            component={Link}
            to={ROUTES.ADMINREGISTER}
            className={classes.submit}>
            Register an Account
          </Button> 
          : null }          		
        { currentUser.currentUser ?          		        		
        <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            component={Link}
            to={ROUTES.ACCOUNT}
            className={classes.submit}>
            My Account
          </Button>
          : null}
        { !currentUser.currentUser ?          		
        <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            component={Link}
            to={ROUTES.PASSWORD_FORGOT}
            className={classes.submit}>
            Forgot Password
          </Button>
        : null} 
        { currentUser.currentUser ?          		
        <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            component={Link}
            to={ROUTES.PASSWORD_UPDATE}
            className={classes.submit}>
            Reset My Password
          </Button>
        : null}        		
        { currentUser.currentUser.roles.ADMIN ? 
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            component={Link}
            to={ROUTES.ADMIN}
            className={classes.submit}>
            Administrator
          </Button>
          : null
        }   
        { currentUser.currentUser ? 
        <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            component={Link}
            to={ROUTES.DASHBOARD}
            className={classes.submit}>
            Real Estate Dashboard
          </Button>
          : null }
        { currentUser.currentUser ? 
        <Button
            onClick={logout}
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"					
            className={classes.submit}>
            Log Out
          </Button>
          : null }
    </Paper>
		</main>
    : null }
    </AuthContext.Consumer>
    )
}

export default withStyles(styles)(HomePage)