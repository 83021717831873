import React, { useState, useContext, useEffect, useCallback} from 'react'
import { Typography, Paper, Avatar, Button, FormControl, Input, InputLabel } from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter, Link, Redirect } from 'react-router-dom'
import * as ROUTES from "../../constants/routes"
import { FirebaseContext } from '../Firebase'
import { AuthContext } from '../../Auth.js';
import { fetchWixSubscriptions, fetchWixMember, getWixPlanAttributes } from '../../utilities/wix'

const styles = theme => ({
	main: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
			width: 400,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginTop: theme.spacing.unit * 8,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
	},
	avatar: {
		margin: theme.spacing.unit,
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing.unit,
	},
	submit: {
		marginTop: theme.spacing.unit * 3,
	},
});

const SignIn = (props) => {

	const firebase = useContext(FirebaseContext);
	const { currentUser } = useContext(AuthContext);
	const { classes } = props
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [error, setError] = useState(null)
	const isInvalid = password === '' || email === '';

	const handleLogin = useCallback(
		async event => {
		event.preventDefault();
		const { email, password } = event.target.elements;
		try {
			let result = await firebase.auth.signInWithEmailAndPassword(email.value, password.value);
			let userId = result.user.uid;
			let userEmail = result.user.email;
			let wixMember = await fetchWixMember(userEmail)
			let subscriptions  = await fetchWixSubscriptions(wixMember._id);
			// Get Roles and Menus available to the user by querying the wix planAttributes collection
			// After fetching write the Roles and Menus to the Firebase Realtime database
			getWixPlanAttributes(subscriptions[0].data.planId)
			.then(result => {
				let attributes = ['menus', 'roles']
				let obj = result.planAttributes[0].attributes;
				for( let prop in obj ){
					// check if obj contains props that need to be written to realtime db
					if(attributes.includes(prop)){
						// create empty object
						let attrObj={};
						// name empty object based on prop string i.e. 'roles' or 'menus'.  Then assign it the prop value obj[prop].  This value is the object for roles and/or menus
						attrObj[prop]=obj[prop];  // this looks like this now:   roles = { 'someprop' : someval} or menus = { 'someprop': [array of values]}
						// assign the attr object to a variable and loop through to find sub props
						let subObj = attrObj[prop];
						for (let subProp in subObj){
							// create an empty object
							let propObj={};
							// name the empty object base on the prop string i.e. 'roles' or 'menus'. Then assign the value of subObj
							propObj[prop]=subObj;
							firebase.user(userId).update(propObj);
						}
					}
				}
			});
			props.history.replace("/products/2to4-unit-analyzer");
		} catch (error) {
			alert(error);
		}
		},
		[props.history]
	);
	
	if (currentUser) {
		return <Redirect to="/" />;
	}

	return (
		<AuthContext.Consumer>
		{ cusrrentUser => !currentUser ?
		<main className={classes.main}>
			<Paper className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Sign in
       			</Typography>	
				<form className={classes.form} onSubmit={handleLogin}>
					<FormControl margin="normal" required fullWidth>
						<InputLabel htmlFor="email">Email Address</InputLabel>
						<Input id="email" name="email" autoComplete="off" autoFocus value={email} onChange={e => setEmail(e.target.value)} />
					</FormControl>
					<FormControl margin="normal" required fullWidth>
						<InputLabel htmlFor="password">Password</InputLabel>
						<Input name="password" type="password" id="password" autoComplete="off" value={password} onChange={e => setPassword(e.target.value)} />
					</FormControl>
					<Button
						disabled={isInvalid}
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						//onClick={login}
						className={classes.submit}>
						Sign in
          			</Button>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="secondary"
						component={Link}
						to={ROUTES.PASSWORD_FORGOT}
						className={classes.submit}>
						Forgot Password
          			</Button>
				</form>
			</Paper>
		</main> : null }
		</AuthContext.Consumer>
	);
}

export default withRouter(withStyles(styles)(SignIn))