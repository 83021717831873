import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
	constructor() {
		app.initializeApp(config)
		this.auth = app.auth()
		this.db = app.database()
	}	
	
	/* Two other wasys to merge authentication user and realtime db user
	Method 1)
	).then(dbUser => {
		// default empty roles
		if(dbUser!== null){
			if (!dbUser.roles) {
				dbUser.roles = {};
			}
		}
		return [this.doGetIdToken(), dbUser]
	}).then(userArray => { 
	// merge auth and db user
		return authUser = {
		uid: authUser.uid,
		email: authUser.email,
		...userArray[1], 
	}

	Method 2)
	.then(dbUser => { 
		// merge auth and db user
			return authUser = {
			uid: authUser.uid,
			email: authUser.email,
			...dbUser, 
		}
	)
	*/

	onAuthUserListener = (next, fallback) => {
		this.auth.onAuthStateChanged(user => {
				if (user) {
					this.user(user.uid)
					.once('value')
					.then(snapshot => {		  
						return snapshot.val();
					}).then((dbUser) => {
					// default empty roles
					if(dbUser!== null){
						dbUser.id = user.uid

						if (!dbUser.roles) {
							dbUser.roles = {};
						}

						if (!dbUser.subscriptions) {
							dbUser.subscriptions = {};
						}

						if (!dbUser.menus) {
							dbUser.menus = {};
						}

					}
					next(dbUser)
					})
				} else {
					fallback();
				}
			}
		)}

	doTest(){
		console.log('firebase access');
		//return 'firebase access'
	}

	
	/*
	setSubscription = (uid, subscriptionIsCurrent) => {
		console.log(subscriptionIsCurrent)
        // A post entry.
        var postData = {
          TwoFourUnitAnalyzerIsCurrent: subscriptionIsCurrent
        };
      
        // Write the data
        var updates = {};
        updates['/users/' + uid + '/subscriptions'] = postData;
      
        return this.db.ref().update(updates);
	}
	*/

	async doGetIdTokenResult () {
		this.auth.currentUser.getIdTokenResult()
		.then((idTokenResult) => {
			// Confirm the user is an Admin.
			if (!!idTokenResult.claims.DEV) {
			// Show admin UI.
			//showAdminUI();
			console.log('1', idTokenResult)
			localStorage.setItem('idToken', idTokenResult.claims.DEV)
			} else {
			// Show regular user UI.
			//showRegularUI();
			console.log('2', idTokenResult)
			localStorage.setItem('idToken', idTokenResult.claims.DEV)
			}
		})
		.catch((error) => {
			console.log(error);
		});
	}

	async doGetIdToken(){
		return this.auth.currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {	
			return idToken;
		// Send token to your backend via HTTPS
		// ...
	  }).catch(function(error) {
		// Handle error
	  });
	}

	doDisplayNameUpdate = (name) =>
		this.auth.currentUser.updateProfile({displayName: name})

	doCreateUserWithEmailAndPassword = (email, password) =>
		this.auth.createUserWithEmailAndPassword(email, password);

	doSignInWithEmailAndPassword = (email, password) =>
		this.auth.signInWithEmailAndPassword(email, password);
	 
	doSignOut = () => this.auth.signOut();
	 
	doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
	 
	doPasswordUpdate = password =>
		this.auth.currentUser.updatePassword(password);	

	addQuote(quote) {
		if(!this.auth.currentUser) {
			return alert('Not authorized')
		}
		return this.db.doc(`users_codedamn_video/${this.auth.currentUser.uid}`).set({
			quote
		})
	}

	addApiKey(hashedApiKey) {
		var today = new Date();
		this.db.ref(`users/${this.auth.currentUser.uid}/apiKeys/` + hashedApiKey).set(
			{
			"api" : "2to4UnitAnalyzer",
			"rateLimit" : 0,
			"created_date" : today.toString(),
			"last_used" : "never",
			"times_used" : 0
			}
		)
	}

	/*
	addApiKey(apiKey) {
		console.log('currUser', this.authUser)
		if(!this.auth.currentUser) {
			return alert('Not authorized')
		}
		return this.db.ref('apiKeys/' + apiKey).set({
			"uid": this.auth.currentUser.uid
		})
	}
	*/

	// *** User API ***
	 
	user = uid => this.db.ref(`users/${uid}`);
	 
	users = () => this.db.ref('users');

	getCurrentUsername() {
		return this.auth.currentUser && this.auth.currentUser.displayName
	}

	/*
	async getCurrentUserQuote() {
		const quote = await this.db.doc(`users_codedamn_video/${this.auth.currentUser.uid}`).get()
		return quote.get('quote')
	}
	*/
}

export default Firebase