export async function getWixPlanAttributes (planId) {
    try{    
        const response = await fetch("https://www.pablosaban.com/_functions/planAttributes/" + planId)
        let planAttributes = await response.json();
        return planAttributes;
    } catch(error) {
        console.log('error', error);
    }
}

export async function fetchWixMember (email) {
    try{
        const response = await fetch('https://www.pablosaban.com/_functions/member/' + email);
        let memberJson = await response.json();
        //console.log(memberJson.user[0]._id)
        //return memberJson.user[0]._id
        return memberJson.user[0]
    } catch (err) {
        return err;
    }      
}

export async function fetchWixSubscriptions (id) {
    try{
        const response = await fetch('https://www.pablosaban.com/_functions/memberSubscriptions/' + id);
        const { subscriptions } = await response.json();
        return subscriptions
    } catch (err) {
        return err;
    } 
}

export async function wixSubscriptionDaysValid (wixPayOrderId) {
    if(wixPayOrderId===''){
        return 7
    } else {
        return 30
    }
}

export async function wixSubscriptionCurrent (validTo) {
    let today = new Date();
    return Boolean(validTo > today);      
}