import React, { useEffect, useState, useContext } from "react";
import { FirebaseContext } from './components/Firebase';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);
  const firebase = useContext(FirebaseContext);

  useEffect(() => {
    let isSubscribed = true;
      firebase.onAuthUserListener(user => {
        setCurrentUser(user)
        setPending(false)
      },
      () => {
        setCurrentUser(null)
        setPending(false)
      }
    );
    return () => isSubscribed = false;
  }, []);

  if(pending){
    return <>Loading...</>
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};