export const RESTRICTED = '/restricted';
//export const DASHBOARD = '/dashboard/default';
export const DASHBOARD = '/products/2to4-unit-analyzer';
export const LOGIN = '/login';
export const LOGINWITHEMAILLINK = '/email-link-login'
export const ADMINREGISTER = '/admin-register';
export const REGISTER = '/register';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGOT = '/pw-forgot';
export const PASSWORD_UPDATE = '/pw-update';
export const PASSWORD_CREATE = '/pw_create';
export const TWOFOURUNITANALYZERCASESTUDY = '/case-study/2to4-unit-analyzer';
export const TWOFOURUNITANALYZER = '/products/2to4-unit-analyzer';
export const APIFORM = '/developer/api-keys';
export const BASICFORMS= '/forms/form-basic';