import React, { useState, useContext, useEffect } from 'react'
import { Typography, Paper, Avatar, Button, FormControl, Input, InputLabel } from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter, Link, Redirect } from 'react-router-dom'
import { FirebaseContext } from '../Firebase'
import { AuthContext } from '../../Auth.js';
import { fetchWixSubscriptions, fetchWixMember, getWixPlanAttributes } from '../../utilities/wix'

const styles = theme => ({
	main: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
			width: 400,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginTop: theme.spacing.unit * 8,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
	},
	avatar: {
		margin: theme.spacing.unit,
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing.unit,
	},
	submit: {
		marginTop: theme.spacing.unit * 3,
	},
});

const SignInWithEmailLink = (props) => {

	const firebase = useContext(FirebaseContext);
	const { currentUser } = useContext(AuthContext);
	const { classes } = props
	const [email, setEmail] = useState('')
	const [error, setError] = useState(null)
	const isInvalid = email === '';
	const [roles, setRoles] = useState({})
	const [menus, setMenus] = useState({})

	useEffect(() => {

	})

	async function handleLogin() {
		try {
			let result = await firebase.auth.signInWithEmailLink(email, window.location.href)
			let userId = result.user.uid;
			let userEmail = result.user.email;
			let wixMember = await fetchWixMember(userEmail)
			let fName = wixMember.firstName
			let lName = wixMember.lastName
			let userName = fName + ' ' + lName
			await firebase.user(userId).update({"email": userEmail, "name": userName});
			let subscriptions  = await fetchWixSubscriptions(wixMember._id);
			//console.log(subscriptions[0])

			// Get Roles and Menus available to the user by querying the wix planAttributes collection
			// After fetching write the Roles and Menus to the Firebase Realtime database
			getWixPlanAttributes(subscriptions[0].data.planId)
			.then(result => {
				let attributes = ['menus', 'roles']
				let obj = result.planAttributes[0].attributes;
				for( let prop in obj ){
					// check if obj contains props that need to be written to realtime db
					if(attributes.includes(prop)){
						// create empty object
						let attrObj={};
						// name empty object based on prop string i.e. 'roles' or 'menus'.  Then assign it the prop value obj[prop].  This value is the object for roles and/or menus
						attrObj[prop]=obj[prop];  // this looks like this now:   roles = { 'someprop' : someval} or menus = { 'someprop': [array of values]}
						// assign the attr object to a variable and loop through to find sub props
						let subObj = attrObj[prop];
						for (let subProp in subObj){
							// create an empty object
							let propObj={};
							// name the empty object base on the prop string i.e. 'roles' or 'menus'. Then assign the value of subObj
							propObj[prop]=subObj;
							firebase.user(userId).update(propObj);
						}
					}
				}
			});
            if(result.additionalUserInfo.isNewUser){
                props.history.replace("/pw_create"); 
			}
		} catch (error) {
			alert(error);
		}
    }

    if (currentUser) {
		return <Redirect to="/" />;
    }

	return (
		<AuthContext.Consumer>
		{ cusrrentUser => !currentUser ?
		<main className={classes.main}>
			<Paper className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Sign in
       			</Typography>	
				<form className={classes.form} onSubmit={e => e.preventDefault() && false }>
					<FormControl margin="normal" required fullWidth>
						<InputLabel htmlFor="email">Email Address</InputLabel>
						<Input id="email" name="email" autoComplete="off" autoFocus value={email} onChange={e => setEmail(e.target.value)} />
					</FormControl>
					<Button
						disabled={isInvalid}
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						onClick={handleLogin}
						className={classes.submit}>
						Sign in
          			</Button>
				</form>
			</Paper>
		</main> : null }
		</AuthContext.Consumer>
	);
}

export default withRouter(withStyles(styles)(SignInWithEmailLink))