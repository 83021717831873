import React, {Component} from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import windowSize from 'react-window-size';
import Aux from "../../../../../../hoc/_Aux";
import NavIcon from "./../NavIcon";
import NavBadge from "./../NavBadge";
import * as actionTypes from "../../../../../../store/actions";
import  { FirebaseContext } from '../../../../../../components/Firebase';

class NavItem extends Component {

    static contextType = FirebaseContext 

    state = {
        currentUser: null,
        allowedMenus:[]
    };

    componentDidMount() {
        const firebase = this.context     
        firebase.onAuthUserListener ( currentUser => {
            if (currentUser) {         
                // User is signed in.
                this.setState({ currentUser:currentUser}, (currentUser) => { 
                    this.setState( { allowedMenus: this.state.currentUser.menus }, (allowedMenus) => {
                        //console.log(this.state.allowedMenus);
                    })            
                })
            } else {
                this.setState({ currentUser: null });
            }          
        })
    } 

    render() {
        let itemTitle = this.props.item.title;
        let groupId = this.props.group.id;
        let itemId = this.props.item.id;
        let subMenus = this.state.allowedMenus[groupId];   
        let mainContent = '';
        let subMenuExists = function n() {
            if (subMenus !== undefined){
                return subMenus.includes(itemId);
            }
        }
        if (subMenuExists()){   
            if (this.props.item.icon) {
                itemTitle = <span className="pcoded-mtext">{this.props.item.title}</span>;
            }

            let itemTarget = '';
            if (this.props.item.target) {
                itemTarget = '_blank';
            }

            let subContent;
            // link to external URL
            if(this.props.item.external) {
                subContent = (
                    <a href={this.props.item.url} target='_blank' rel='noopener noreferrer'>
                        <NavIcon items={this.props.item}/>
                        {itemTitle}
                        <NavBadge layout={this.props.layout} items={this.props.item}/>
                    </a>
                );
            // link to regualar page
            } else {
                subContent = (
                    <NavLink to={this.props.item.url} className="nav-link" exact={true} target={itemTarget}>
                        <NavIcon items={this.props.item}/>
                        {itemTitle}
                        <NavBadge layout={this.props.layout} items={this.props.item}/>
                    </NavLink>
                );
            }
            // horizontal layout
            if (this.props.layout === 'horizontal') {
                mainContent = (
                    <li onClick={this.props.onItemLeave}>{subContent}</li>
                );
            // vertical layout
            } else {
                // smaller screens
                if (this.props.windowWidth < 992) {
                    mainContent = (
                        <li className={this.props.item.classes} onClick={this.props.onItemClick}>{subContent}</li>
                    );
                } else {
                    mainContent = (
                        <li className={this.props.item.classes}>{subContent}</li>
                    );
                }
            }
        
        } else {
            mainContent = '';
        }

        return (
            <Aux>
                {mainContent}
            </Aux>
        );
    }
}

const mapStateToProps = state => {
    return {
        layout: state.layout,
        collapseMenu: state.collapseMenu
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onItemClick: () => dispatch({type: actionTypes.COLLAPSE_MENU}),
        onItemLeave: () => dispatch({type: actionTypes.NAV_CONTENT_LEAVE})
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (windowSize(NavItem)));
