import React, {useContext, useState}   from 'react';
import DEMO  from './../../../../../store/constant';
import Aux from "../../../../../hoc/_Aux";
import { FirebaseContext } from '../../../../../components/Firebase';
import { useAuthorization } from '../../../../../components/Session'
import { AuthContext } from '../../../../../Auth.js';

const NavLogo = (props) => {

	const firebase = useContext(FirebaseContext)
    const { currentUser } = useContext(AuthContext);
	const condition = currentUser => !!currentUser;
	const authorization = useAuthorization(condition,props)

    let toggleClass = ['mobile-menu'];
    if (props.collapseMenu) {
        toggleClass = [...toggleClass, 'on'];
    }

    return (
        <AuthContext.Consumer>
            { currentUser => 
            currentUser.currentUser ? 
        <Aux>
            <div className="navbar-brand header-logo">
                 <a href={DEMO.HOME_LINK} className="b-brand">
                    <div className="b-bg">
                        <i className="feather icon-trending-up" />
                    </div>
                    <span className="b-title"><small>{currentUser.currentUser.companyName}</small></span>
                 </a>
                <a href={DEMO.BLANK_LINK} className={toggleClass.join(' ')} id="mobile-collapse" onClick={props.onToggleNavigation}><span /></a>
            </div>
        </Aux>
        : null
        }
        </AuthContext.Consumer>
    );
};

export default NavLogo;
