import React from 'react';
import {Row, Col, Card, Form, Button, InputGroup, FormControl, DropdownButton, Dropdown, Accordion} from 'react-bootstrap';
import Aux from "../../hoc/_Aux";
import { Route , withRouter} from 'react-router-dom';
import 'react-tabulator/lib/styles.css';
import { ReactTabulator } from 'react-tabulator';
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import Tabulator from "tabulator-tables"; //import Tabulator library
import ModalComponent from "./ModalComponent";
import CopyToClipboard from "./CopyToClipboard";
import  { FirebaseContext } from '../../components/Firebase';
import { AuthContext } from '../../Auth.js';
import { fetchWixMember, fetchWixSubscriptions, wixSubscriptionCurrent, wixSubscriptionDaysValid } from '../../utilities/wix'

//define custom mutator
var roiDiff = function(value, data, type, params, component){
	//value - original value of the cell
	//data - the data for the row
	//type - the type of mutation occurring  (data|edit)
	//params - the mutatorParams object from the column definition
	//component - when the "type" argument is "edit", this contains the cell component for the edited cell, otherwise it is the column component for the column
	var roi = data.ROI;
	var proi = data.PROI;
	return Math.round((proi-roi)*100)/100; //return the new value for the cell data.
}

var inlineRent = function(value, data, type, params, component){
	if (data.TotalRent == 0){
		return data.PotentialTotalRent;		
	} else {
		return data.TotalRent
	}
}

var cashFlow = function(value, data, type, params, component){
	if (data.TotalRent == 0){
		return data.PotentialCashFlow;		
	} else {
		return data.CashFlow
	}
}

var roi = function(value, data, type, params, component){
	if (data.TotalRent == 0) {
		return data.PROI;		
	} else {
		return data.ROI;
	}
}

var colorFormatter = function(cell, formatterParams){
    var value = cell.getValue();
    cell.getElement().style.backgroundColor = formatterParams.color;
    return value;
};

var zeroRentColorFormatter = function(cell, formatterParams){
	var totalRent = cell.getData().TotalRent;
    var value = cell.getValue();
    if(totalRent == 0){
       	cell.getElement().style.backgroundColor = formatterParams.color_0;
       	return value;
    }else{
    	cell.getElement().style.backgroundColor = formatterParams.color_gtz;
       	return value;
    }
};

var toggleClass = function(elem, cls){
    elem.classList.toggle(cls);
};

// Toggle element visibility
 var toggleVisible = function(elem){
        elem.classList.toggle('is-visible');
    };

//toggle for table formatter
var subTableToggleFormatter = function(cell, formatterParams){

    const id = cell.getData().MLS;
    cell.getElement().classList.add('hide-show');
    var div = document.createElement("div");
    div.setAttribute('class', "tabulator-responsive-collapse-toggle");
    div.classList.add('hide-show')
    var spanOpen = document.createElement("span");
    spanOpen.setAttribute('class', "tabulator-responsive-collapse-toggle-open");
    spanOpen.classList.add('hide-show')
    spanOpen.appendChild(document.createTextNode('+'));
    div.appendChild(spanOpen);
    
    return div;
}

const columns = [
 
/*
    { title: "List_Price", field: "List_Price", align: "right", formatter:function(cell, formatterParams){
           var value = cell.getValue();
            if(value > 0){
                return "<span style='color:red; font-weight:bold;'>" + value + "</span>";
            }else{
                return value;
            }
        },
            cellClick:function(e, cell){
            const id = cell.getData().MLS;
            // Toggle the content
            var elem = document.getElementsByClassName("subTable" + id)[0];
            toggle(elem);
            }
    },
*/

    {formatter:"rowSelection", titleFormatter:"rowSelection", align:"center", headerSort:false, cellClick:function(e, cell){
            cell.getRow().toggleSelect();
        }
    },
    {formatter:subTableToggleFormatter, title:"+/-", width:30, minWidth:30, align:"center", resizable:false, headerSort:false, 
        cellClick:function(e, cell){
            const id = cell.getData().MLS;
            // Toggle the content
            var elem = document.getElementsByClassName("subTable" + id)[0];
            toggleVisible(elem);
            var cellElement = cell.getElement();
            if (!cellElement.childNodes[0].childNodes[0].classList.contains('open')){
                //hack to list the classes in the correct order so foreach loop will return true on final iteration.  See rowClick()
                cellElement.childNodes[0].childNodes[0].classList.remove("hide-show");
                cellElement.childNodes[0].childNodes[0].classList.add("open");
                cellElement.childNodes[0].childNodes[0].classList.add("hide-show");
                cellElement.childNodes[0].childNodes[0].textContent = '-' ;
            } else {
                cellElement.childNodes[0].childNodes[0].classList.remove("open")
                cellElement.childNodes[0].childNodes[0].textContent = '+' ;
            }

        }
    },
    { title: "MLS", field: "MLS", align: "center", headerFilter:true },
    { title: "Area", field: "Area", width:100, align: "center", headerFilter:true, headerFilter:"number", headerFilter:"select", headerFilterParams:{values:true}, headerFilterPlaceholder:"Area...", headerFilterFunc:"=" }, 
    {//create column group
    title:"Income/ROI",
    columns:[
    		{ title: "Total Rent", field: "TotalRent", width:130, align: "right", /*formatter:colorFormatter, formatterParams:{color:"#B2D8E1"}*/ },
   			{ title: "Cash Flow (Mo)", field: "CashFlow", width:130, align: "right", /*formatter:colorFormatter, formatterParams:{color:"#B2D8E1"}*/ },
   			{ title: "ROI", field: "ROI", width:130, align: "right", headerFilter:"number", headerFilterPlaceholder:"greater than...", headerFilterFunc:">=", /*formatter:colorFormatter, formatterParams:{color:"#B2D8E1"}*/ },
   		]
    },
    /*
    {title:"Income/ROI",
    columns:[
    		{title:"Total Rent", field:"InlineRent", mutator:inlineRent, width:130, visible:false, formatter:zeroRentColorFormatter, formatterParams:{color_0:"#ffc107", color_gtz:"#B2D8E1"}  },
   			{ title: "Cash Flow (Mo)", field: "InlineCashFlow", width:130, align: "right", visible:false, mutator:cashFlow, formatter:zeroRentColorFormatter, formatterParams:{color_0:"#ffc107", color_gtz:"#B2D8E1"} },
   			{ title: "ROI", field: "InlineROI", width:130, align: "right", headerFilter:"number", headerFilterPlaceholder:"greater than...", headerFilterFunc:">=", visible:false, mutator:roi, formatter:zeroRentColorFormatter, formatterParams:{color_0:"#ffc107", color_gtz:"#B2D8E1"} },    		
    	]
    },
    {
    title:"Market and Potential Income/ROI",
    columns:[
    		{ title: "Market Total Rent", field: "PotentialTotalRent", width:130, align: "right"},
   			{ title: "Potential Cash Flow (Mo)", field: "PotentialCashFlow", width:130, align: "right"},
   			{ title: "Potential ROI", field: "PROI", align: "right", width:130},
   		]
    },
    {title:"ROI Diff", field:"ROIDiff", mutator:roiDiff, width:130},
    */

    { title: "Tot_Units", field: "Tot_Units", align: "center", headerFilter:"number", headerFilterFunc:"=" },
    { title: "List_Price", field: "List_Price", width:150, align: "right", headerFilter:"number", headerFilterPlaceholder:"no more than...", headerFilterFunc:"<="},

    { title: "City", field: "City", width:100, align: "center", headerFilter:true, headerFilter:"select", headerFilterParams:{values:true}, headerFilterPlaceholder:"City..."  },
    { title: "State", field: "State", width:100, align: "center", headerFilter:true, headerFilter:"select", headerFilterParams:{values:true}, headerFilterPlaceholder:"State..."   },
    { title: "Zip", field: "Zip", width:100, align: "center", headerFilter:true, headerFilter:"select", headerFilterParams:{values:true}, headerFilterPlaceholder:"Zip..."   },
]

class TabulatorTable extends React.Component {

    static contextType = FirebaseContext  

    constructor(props) {
        super(props);
        let pathname = props.location.pathname;
        let fetchURL = '';
        if (pathname == '/case-study/2to4-unit-analyzer'){
            fetchURL = 'https://mls-grid-ui.firebaseapp.com/propertiesJson.json';
        }else{
            fetchURL = 'https://cgc-backoffice.azurewebsites.net/listing?rt=a&apikey=cartergroup&params=rate:' + this.props.rate + '|downpayment:' + this.props.downpayment + '|insurance:' + this.props.insurance + '|closingcosts:' + this.props.closingCosts + '|managementfee:' + this.props.managementFee + '|closedrents:' + this.props.closedRents;
        }
        this.tableRef = React.createRef(); //create reference object
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            errorMsg: "",
            modal: false,
            rate: this.props.rate,
            downpayment:this.props.downpayment,
            insurance: this.props.insurance,
            closingCosts: this.props.closingCosts,
            managementFee: this.props.managementFee,
            closedRents: this.props.closedRents,
            textToCopy: 'None',
            copyStatus: false,
            loadedRecords: '0',
            filteredRecords: '0',
            selectedRecords: 0,
            inlineRent: false,
            fetchURL: fetchURL,
            currentUser: null,
            _isMounted: false,
            _isSubscribed: false,
            wixSubscriptionValidTo: null
        };   
    }

    downloadData = () => {
      var tabulator = this.tableRef.current.table;
      tabulator.download("csv", "data.csv")
    };

    componentDidMount() {
        const firebase = this.context
        this.fetchData();          
        this.setState({_isMounted:true},

        firebase.onAuthUserListener ( currentUser => {

                if (currentUser) {            
                    // User is signed in.
                    this.setState({ currentUser:currentUser}, (currentUser) => { 
                        fetchWixMember(this.state.currentUser.email)
                        .then(async (member) => {
                            const subscriptions = await fetchWixSubscriptions(member._id);
                            const validRange = await wixSubscriptionDaysValid(subscriptions[0].data.wixPayOrderId)
                            let validFrom = new Date(subscriptions[0].data.validFrom)
                            validFrom.setDate(validFrom.getDate() + 50);
                            let validTo =  new Date(validFrom);
                            const subscriptionCurrent = await wixSubscriptionCurrent(validTo);
                            if(subscriptionCurrent){
                                this.setState({_isSubscribed:true});
                                this.fetchData() ;
                            }
                        })
                    })           
                } else {
                    this.setState({ currentUser: null });
                    alert('Please login first.')
                    this.props.history.push('/login');
                }          
            })
        )
    }  

    fetchData = () => {
    //var tabulator = this.tableRef.current.table;
    this.setState({ items: [] }); 
    fetch(this.state.fetchURL)
    .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.properties,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
    }

    handleChange = (e) => {
        this.setState({[e.target.id]: e.target.value}, this.setFetchURL);        
    };

    setFetchURL = () => {
        const { rate, downpayment, insurance, closingCosts, managementFee, closedRents } = this.state;
        if (this.props.location.pathname=='/case-study/2to4-unit-analyzer'){
            this.setState({fetchURL: 'https://mls-grid-ui.firebaseapp.com/propertiesJson.json'}, console.log(this.state))            
        } else {
            this.setState({fetchURL: 'https://cgc-backoffice.azurewebsites.net/listing?rt=a&apikey=cartergroup&params=rate:' + rate + '|downpayment:' + downpayment + '|insurance:' + insurance + '|closingcosts:' + closingCosts + '|managementfee:' + managementFee + '|closedrents:' + closedRents}, console.log(this.state));
        }                   
    }
	
    handleRadioChange = (e, radioGroup, val) => {
        this.setState({ [radioGroup] : val}, this.setFetchURL);
    }

    rowClick = (e, row) => {
        //console.log(e.target);
        let elem = row.getElement();
        let trigger = e.target;

        let classes = trigger.classList;
        let iterator = classes.values();

        classes.forEach(
            function(value, key, listObj) {
                if (value=='hide-show' && !elem.classList.contains('tabulator-selected')){
                    elem.classList.add("tabulator-highlighted");
                } else if (!elem.classList.contains('tabulator-selected')){
                    elem.classList.toggle("tabulator-highlighted");
                }
            }
        );
    }

    rowSelected = (row) => {
        let elem = row.getElement();
        elem.classList.add("tabulator-selected");
        elem.classList.remove("tabulator-highlighted");
		let str = this.state.textToCopy;
	    str = this.addMLSNum(str, row.getData().MLS);
	    this.setState({ copyStatus: false});
	   	this.setState({ textToCopy: str});
	};

    rowDeselected = (row) => {
        let elem = row.getElement();
        elem.classList.remove("tabulator-selected");
        elem.classList.remove("tabulator-highlighted");
        let str = this.state.textToCopy;
        str = this.removeMLSNum(str, row.getData().MLS);
        this.setState({ copyStatus: false});
        this.setState({ textToCopy: str});
    };

	addMLSNum = (mlsNums, mlsNum) => {
		let str = mlsNums;
		if (str == 'None'){
			str = '';
		}
		str = str + ', ' + mlsNum;
		str = str.replace(/^\,|\,$/g, '');
        this.setState({selectedRecords:this.state.selectedRecords+1});
		return str;
	}

	removeMLSNum = (mlsNums, mlsNum) => {
		let str = mlsNums.replace(mlsNum, '');
		str = str.replace(/[, ]+/g, ' ').trim();
		str = str.replace(/[ ]+/g, ', ').trim();
		if (str == ''){
			str = 'None';
		}
        this.setState({selectedRecords:this.state.selectedRecords-1});
		return str;
	}

    dataLoaded = () => {
        if (this.state.isLoaded){
            var tabulator = this.tableRef.current.table;
            this.setState({loadedRecords:tabulator.getDataCount()});
        }
    }

    dataFiltered = (activeRows) => {
        if (this.state.isLoaded){
            var tabulator = this.tableRef.current.table;
            this.setState({filteredRecords:activeRows });
        }
    }

    toggleColumn = () => {
    	var tabulator = this.tableRef.current.table;
    	tabulator.blockRedraw();
    	tabulator.toggleColumn("TotalRent"); ////toggle the visibility of the "name" column
    	tabulator.toggleColumn("CashFlow");
    	tabulator.toggleColumn("ROI");
    	tabulator.toggleColumn("PotentialTotalRent"); ////toggle the visibility of the "name" column
    	tabulator.toggleColumn("PotentialCashFlow");
    	tabulator.toggleColumn("PROI");
    	tabulator.toggleColumn("InlineRent");
    	tabulator.toggleColumn("InlineCashFlow");
    	tabulator.toggleColumn("InlineROI");
    	tabulator.restoreRedraw();
    	this.setState({inlineRent: !this.state.inlineRent})
    }

    subTableFormatter = (row) => {
        //create and style holder elements
        let streetNum = row.getData().propertyDetails[0].Street;
        let compassPoint = row.getData().propertyDetails[0].CP;
        let streetName = row.getData().propertyDetails[0].Str_Name;
        var holderEl = document.createElement("div");
        var wrapperSpan = document.createElement("span");
        wrapperSpan.appendChild(document.createTextNode(streetNum + ' ' + compassPoint + ' ' + streetName));
        holderEl.appendChild(wrapperSpan);
        var tableEl = document.createElement("div");

        const id = row.getData().MLS;

        holderEl.style.boxSizing = "border-box";
        holderEl.style.padding = "10px 30px 10px 10px";
        holderEl.style.borderTop = "1px solid #333";
        holderEl.style.borderBotom = "1px solid #333";
        holderEl.style.background = "#ddd";
        holderEl.setAttribute('class', "subTable" + id + "");
        tableEl.style.border = "1px solid #333";

        holderEl.appendChild(tableEl);

        row.getElement().appendChild(holderEl);

        var subTable = new Tabulator(tableEl, {
            layout:"fitColumns",
            data:row.getData().propertyDetails,
            columns:[
                {//create column group
                title:"Out Flows (Expenses)",
                    columns:[
                        { title: "P&I", field: "PnI", align: "right", headerSort:false }, 
                        { title: "PITI", field: "PITI", align: "right", headerSort:false },
                        { title: "Taxes (Mo)", field: "TaxesMo", align: "right", headerSort:false },
                        { title: "Ins (Mo)", field: "InsMo", align: "right", headerSort:false },
                    ]
                },
                { title: "Downpayment", field: "Downpayment", align: "right", headerSort:false },
                { title: "Loan Balance", field: "LoanBalance", align: "right", headerSort:false },
                { title: "Taxes", field: "Taxes", align: "right", headerSort:false },
                /*
                { title: "Street", field: "Street", align: "center" },
                { title: "Str_Name", field: "Str_Name", align: "center" },
                { title: "CP", field: "CP", align: "center" },
                */
                {//create column group
                title:"Unit 1",
                    columns:[
                        { title: "Beds", field: "Of_Bdrms_Unit_1", align: "center", headerSort:false },
                        { title: "F. Baths", field: "Of_FullBaths_Unit_1", align: "center", headerSort:false },  
                        { title: "H. Baths", field: "Of_HalfBaths_Unit_1", align: "center", headerSort:false },  
                        { title: "Rent 1", field: "Rnt_Un_1", align: "right", headerSort:false },
                        { title: "Avg Rent 1", field: "AvgRnt_Un_1", align: "right", headerSort:false },
                    ]
                },    
                {//create column group
                title:"Unit 2",
                    columns:[
                        { title: "Beds", field: "Of_Bdrms_Unit_2", align: "center", headerSort:false },
                        { title: "F. Baths", field: "Of_FullBaths_Unit_2", align: "center", headerSort:false },  
                        { title: "H. Baths", field: "Of_HalfBaths_Unit_2", align: "center", headerSort:false },  
                        { title: "Rent 2", field: "Rnt_Un_2", align: "right", headerSort:false },
                        { title: "Avg Rent 2", field: "AvgRnt_Un_2", align: "right", headerSort:false },
                    ]
                },
                {//create column group
                title:"Unit 3",
                    columns:[
                        { title: "Beds", field: "Of_Bdrms_Unit_3", align: "center", headerSort:false },
                        { title: "F. Baths", field: "Of_FullBaths_Unit_3", align: "center", headerSort:false },  
                        { title: "H. Baths", field: "Of_HalfBaths_Unit_3", align: "center", headerSort:false },  
                        { title: "Rent 3", field: "Rnt_Un_3", align: "right", headerSort:false },
                        { title: "Avg Rent 3", field: "AvgRnt_Un_3", align: "right", headerSort:false },
                    ]
                },
                {//create column group
                title:"Unit 4",
                    columns:[
                        { title: "Beds", field: "Of_Bdrms_Unit_4", align: "center", headerSort:false },
                        { title: "F. Baths", field: "Of_FullBaths_Unit_4", align: "center", headerSort:false },  
                        { title: "H. Baths", field: "Of_HalfBaths_Unit_4", align: "center", headerSort:false },  
                        { title: "Rent 4", field: "Rnt_Un_4", align: "right", headerSort:false },
                        { title: "Avg Rent 4", field: "AvgRnt_Un_4", align: "right", headerSort:false },
                    ]
                },
                /*
                { title: "Reps_Maint_Exp", field: "Reps_Maint_Exp", align: "left" },
                { title: "Water_Sewer_Exp", field: "Water_Sewer_Exp", align: "left" },
                */
                { title: "Parking", field: "Parking", align: "center", headerSort:false },
                { title: "Parking_Spaces", field: "Parking_Spaces", align: "right", headerSort:false },
                { title: "Market_Time", field: "Market_Time", align: "center", headerSort:false },
                { title: "Mkt_Tm", field: "Mkt_Tm", align: "center", headerSort:false },
                { title: "Stat", field: "Stat", align: "center", headerSort:false },
                { title: "Lot_Dim", field: "Lot_Dim", align: "center", headerSort:false },
            ]
        })
        holderEl.classList.add("toggle-content");
    };

    render() {
        const { _isMounted, _isSubscribed, items, error, rate, downpayment, insurance, closingCosts, managementFee, closedRents, textToCopy, copyStatus, loadedRecords, filteredRecords, selectedRecords, inlineRent} = this.state;
        const options = {
            pagination: 'local', 
            paginationSize:20, 
            paginationSizeSelector:[20, 50, 100],
            layout:'fitDataFill',
            movableColumns: true,
            movableRows: true,
            //selectable:true,
            columnVertAlign:"bottom",
            downloadDataFormatter: (data) => data,
            downloadReady: (fileContents, blob) => blob,
            placeholder:"<div class='spinner-border' style='display:inline-block;'></div>",
            rowSelected:(row) => this.rowSelected(row),
            rowDeselected:(row) => this.rowDeselected(row),
            /*
            rowSelectionChanged:function(data, rows){
                //update selected row counter on selection change
                document.getElementById("select-stats").innerHTML = data.length;
            },
            */
        };
        return ( 
            <AuthContext.Consumer>
            {currentUser => _isSubscribed || (this.props.location.pathname == '/case-study/2to4-unit-analyzer') ?
            <Aux>
                <Row>
                    <Col>
                    <Accordion defaultActiveKey="0">
                        <Card>
                            <Card.Header>
                                <Card.Title as="h5">Assumptions</Card.Title>
                            </Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                <Card.Subtitle className="mb-2 mt-2 text-muted">Current assumptions set to <code>rate={rate!=null?rate:'4.50'}%</code> | <code>downpayment={downpayment!=null?downpayment:'25'}%</code> | <code>annual insurance=${insurance!=null?insurance:'1500'}</code> | <code>closing costs={closingCosts!=null?closingCosts:'2'}%</code> | <code>management fee={managementFee!=null?managementFee:'10'}%</code> | <code>closed rents={closedRents!=null?closedRents/30:'12'} months back</code></Card.Subtitle>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                            <Card.Body>                          
                                <div className="col-md-3">
                                    <label htmlFor="basic-url">Rate</label>
                                    <div className="mb-3 input-group"><input id="rate" onChange={this.handleChange} aria-label="Percentage (00.00)" className="form-control" placeholder="4.50"></input><div className="input-group-append"><span className="input-group-text">%</span></div></div>
                                    <label htmlFor="basic-url">Downpayment (%)</label>
                                    <div className="mb-3 input-group"><input id="downpayment" onChange={this.handleChange} aria-label="Percentage (00.00)" className="form-control" placeholder="25"></input><div className="input-group-append"><span className="input-group-text">%</span></div></div>
                                    <label htmlFor="basic-url">Insurance (annual)</label>
                                    <div className="mb-3 input-group"><div className="input-group-prepend"><span className="input-group-text">$</span></div><input id="insurance" onChange={this.handleChange} aria-label="Amount (to the nearest dollar)" className="form-control" placeholder="1500"></input><div className="input-group-append"><span className="input-group-text">.00</span></div></div>
                                    <label htmlFor="basic-url">Closing Costs</label>
                                    <div className="mb-3 input-group"><input id="closingCosts" onChange={this.handleChange} aria-label="Percentage (00.00)" className="form-control" placeholder="2.00"></input><div className="input-group-append"><span className="input-group-text">%</span></div></div>
                                    <label htmlFor="basic-url">Management Fee</label>
                                    <div className="mb-3 input-group"><input id="managementFee" onChange={this.handleChange} aria-label="Percentage (00.00)" className="form-control" placeholder="10.00"></input><div className="input-group-append"><span className="input-group-text">%</span></div></div>                                    
                                    <label htmlFor="basic-url">Average Rents</label>
                                    <Col>  
                                        <Form.Group id="rents" className="mb-3">
                                            <Form.Check
                                                inline
                                                custom
                                                defaultChecked
                                                type="radio"
                                                label="12 months"
                                                name="closedRents"
                                                id="twelve-months"
                                                value="360"
                                                onChange={(event) => this.handleRadioChange(event, "closedRents", "360")} 
                                            /> 
                                            <Form.Check
                                                inline
                                                custom
                                                type="radio"
                                                label="6 months"
                                                name="closedRents"
                                                id="six-months"
                                                value="180"
                                                onChange={(event) => this.handleRadioChange(event, "closedRents", "180")} 
                                            /> 
                                            <Form.Check
                                                inline
                                                custom
                                                type="radio"
                                                label="3 months"
                                                name="closedRents"
                                                id="three-months"
                                                value="90"
                                                onChange={(event) => this.handleRadioChange(event, "closedRents", "90")} 
                                            />
                                        </Form.Group>
                                    </Col>
                                    <ModalComponent fetchData={this.fetchData} rate={rate} downpayment={downpayment} insurance={insurance} closingCosts={closingCosts} managementFee={managementFee} closedRents={closedRents} /> 
                                </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        </Accordion>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <Card.Header>
                                <Card.Title as="h5">Available Properties</Card.Title>
                            </Card.Header>
                            <Card.Body>
                            <button type="button" className="btn btn-primary float-right" onClick={this.downloadData}>Download Data</button>
                            {/*<button type="button" className="btn btn-primary float-right" onClick={this.toggleColumn}> {inlineRent == false ? 'Market Rents Inline' : 'Market Rents Separated'}</button>*/}    
                            <div>Tolal Listings: {loadedRecords}</div>
                            <div>Filtered Listings: {filteredRecords}</div>
                            <div>Selected Listings: {selectedRecords}</div>
                            {/*<span id="select-stats"></span>*/}
                            <div className="mb-3 mt-3">Selected MLS Numbers: <span id="selected-mls-nums" className="text-info">{this.state.textToCopy}</span> {this.state.textToCopy != 'None' ? <CopyToClipboard textToCopy={textToCopy} copyStatus={this.state.copyStatus} /> : null} </div>
                                <ReactTabulator
                                    ref={this.tableRef} 
                                    data={items}
                                    columns={columns}
                                    tooltips={true}
                                    layout={"fitData"}
                                    options={options}
                                    rowClick={this.rowClick}
                                    dataLoaded={this.dataLoaded}
                                    dataFiltered={(filters,rows) => this.dataFiltered(rows.length)}
                                    rowFormatter={this.subTableFormatter}
                                />  
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>      
            </Aux>  : <a href="https://www.pablosaban.com">Please subscribe</a> /*< HomePage />*/  
        }
        </AuthContext.Consumer>
        );
    }
}

//export default TabulatorTable;
export default withRouter(TabulatorTable);