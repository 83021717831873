import React, { useState, useEffect, useContext } from 'react'
import { Typography, Paper, Avatar, Button, FormControl, Input, InputLabel } from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import withStyles from '@material-ui/core/styles/withStyles'
import { Link, withRouter } from 'react-router-dom'
import * as ROUTES from "../../constants/routes"
import { FirebaseContext } from '../Firebase'

const styles = theme => ({
	main: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
			width: 400,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginTop: theme.spacing.unit * 8,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
	},
	avatar: {
		margin: theme.spacing.unit,
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing.unit,
	},
	submit: {
		marginTop: theme.spacing.unit * 3,
	},
});

function ForgotPasswordPage(props) {

	const firebase=useContext(FirebaseContext)
	const { classes } = props
	const [email, setEmail] = useState('')
	const [error, setError] = useState(null)
	const isInvalid = email === '';

	return (
		<main className={classes.main}>
			<Paper className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Forgot Password
       			</Typography>	
				<form className={classes.form} onSubmit={e => e.preventDefault() && false}>
					<FormControl margin="normal" required fullWidth>
						<InputLabel htmlFor="email">Email Address</InputLabel>
						<Input id="email" name="email" autoComplete="off" autoFocus value={email} onChange={e => setEmail(e.target.value)} />
					</FormControl>
					<Button
						disabled={isInvalid}
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						onClick={reset}
						className={classes.submit}>
						Reset My Password
          			</Button>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="secondary"
						component={Link}
						to={ROUTES.LOGIN}
						className={classes.submit}>
						Back to Login
          			</Button>
	          		<Button
						type="submit"
						fullWidth
						variant="contained"
						color="secondary"
						component={Link}
						to={ROUTES.HOME}
						className={classes.submit}>
						HOME
	          		</Button>
				</form>
			</Paper>
		</main>
	)

	async function reset() {
		try {
			await firebase.doPasswordReset(email)
			setEmail('')
			alert('Please check your email.')
			props.history.replace('/home')
		} catch(error) {
			alert(error.message)
		}
	}
}

export default withRouter(withStyles(styles)(ForgotPasswordPage))