import {useEffect, useState, useContext} from 'react';
import { withRouter } from 'react-router-dom';
import { FirebaseContext } from '../Firebase';
import * as ROUTES from '../../constants/routes';

function useAuthorization(condition, props) {
	const firebase = useContext(FirebaseContext)

	useEffect(() => {
			if (props.history !== undefined){
			let isSubscribed = true;
			if (isSubscribed && props.history !== undefined){
			firebase.onAuthUserListener(authUser => {
					if (!condition(authUser)) {
						props.history.push(ROUTES.RESTRICTED);
						}
					},
					() => {props.history.push(ROUTES.LOGIN)},
					);
				}
				return () => isSubscribed = false;
			}
		},[])
}

export default useAuthorization;

