import React, { useContext }  from 'react'
import { useAuthorization } from '../Session';
import * as ROLES from '../../constants/roles';
import { AuthContext } from '../../Auth.js';

const AccountPage = props => {

	const { currentUser } = useContext(AuthContext);
	const condition = currentUser => !!currentUser;
	const authorization = useAuthorization(condition,props)
 
	return (  currentUser  ? 
		<div>Account:{currentUser.name}</div> 
		: null )
}

export default AccountPage;
 
