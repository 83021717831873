import React, { useState } from 'react'

const CopyToClipboard = (props) => {

  //console.log(props.copyStatus)

  const [copyStatus, setCopyStatus] = useState(
    null
  );

  const textToClipboard = () => {
    setCopyStatus('copied!');
    document.getElementById('c-status').style.display='inline';
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = props.textToCopy;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
        setTimeout(function () {
        document.getElementById('c-status').style.display='none';
    }, 500);
    return false;
  };

  return (
    <a href="javascript:void(0);" onClick={textToClipboard}><i className="ml-2 mr-2 feather text-secondary icon-copy"></i><span id="c-status" className="text-secondary">{copyStatus}</span></a>
  );
}

export default CopyToClipboard;