import React, {useContext, useState, useEffect}  from 'react'
import { useAuthorization } from '../Session';
import { FirebaseContext } from '../Firebase';
import * as ROLES from '../../constants/roles';
import { AuthContext } from '../../Auth.js';

const AdminPage = props => {

	const [ loading, setLoading ] = useState(false);
  const [ users, setUsers ] = useState([]);
	const firebase = useContext(FirebaseContext);
  const { currentUser } = useContext(AuthContext);
	const condition = currentUser => currentUser && currentUser.roles.ADMIN;
  const authorization = useAuthorization(condition,props)

  useEffect(() => {
    setLoading(true)
    firebase.users().on('value', snapshot => {
      const usersObject = snapshot.val();
      const usersList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      }));
      setLoading(false)
      setUsers(usersList)
    })
  },[])

  const UserList = ({ users }) => (
    <ul>
      {users.map(user => (
        <li key={user.uid}>
          <span>
            <strong>ID:</strong> {user.uid}
          </span>
          <span>
            <strong>E-Mail:</strong> {user.email}
          </span>
          <span>
            <strong>Username:</strong> {user.name}
          </span>
        </li>
      ))}
    </ul>
  );

  return (
    <AuthContext.Consumer>
      { currentUser => currentUser.currentUser && !!currentUser.currentUser.roles.ADMIN ?
        <div>
          <div>Admin: {currentUser.currentUser.name}</div>
          {loading && <div>Load...</div>}
          <UserList users={users} />
        </div> : null
      }
    </AuthContext.Consumer>
  );
}

export default AdminPage;