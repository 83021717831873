import React, { useState, useContext } from 'react';
import { Typography, Paper, Avatar, Button, FormControl, FormControlLabel, Input, InputLabel, Checkbox } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link, withRouter } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { FirebaseContext } from '../Firebase';
import { AuthUserContext, useFirebaseAuthentication } from '../Session';
import { createAuthUser } from '../../utilities/users'

const styles = theme => ({
	main: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
			width: 400,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginTop: theme.spacing.unit * 8,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
	},
	avatar: {
		margin: theme.spacing.unit,
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing.unit,
	},
	submit: {
		marginTop: theme.spacing.unit * 3,
	},
})

function AdminRegister(props) {
	const condition = authUser => !!authUser;
	const firebase = useContext(FirebaseContext);
	const authUser = useFirebaseAuthentication(firebase);
	const { classes } = props
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [isAdmin , setIsAdmin] = useState(false)
	const [isDeveloper , setIsDeveloper] = useState(false)
	const [error, setError] = useState(null)
	const [roles, setRoles] = useState({})
	const [menus, setMenus] = useState({})
	const isInvalid =
      password !== confirmPassword ||
      password === '' ||
      email === '' ||
      name === '';

	return (
		<main className={classes.main}>
			<Paper className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Register Account
       			</Typography>
				<form className={classes.form} onSubmit={e => e.preventDefault() && false }>
					<FormControl margin="normal" required fullWidth>
						<InputLabel htmlFor="name">Name</InputLabel>
						<Input id="name" name="name" autoComplete="off" autoFocus value={name} onChange={e => setName(e.target.value)} />
					</FormControl>
					<FormControl margin="normal" required fullWidth>
						<InputLabel htmlFor="email">Email Address</InputLabel>
						<Input id="email" name="email" autoComplete="off" value={email} onChange={e => setEmail(e.target.value)}  />
					</FormControl>
					<FormControl margin="normal" required fullWidth>
						<InputLabel htmlFor="password">Password</InputLabel>
						<Input name="password" type="password" id="password" autoComplete="off" value={password} onChange={e => setPassword(e.target.value)}  />
					</FormControl>
					<FormControl margin="normal" required fullWidth>
						<InputLabel htmlFor="confimrPassword">Confirm Password</InputLabel>
						<Input name="confirmPassword" type="password" id="confirmPassword" autoComplete="off" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)}  />
					</FormControl>
					<FormControlLabel
          				control={<Checkbox name="isAdmin" type="checkbox" id="isAdmin" autoComplete="off" checked={isAdmin} onChange={e => setIsAdmin(e.target.checked)}  />}
          				label="Admin"
						  labelPlacement="start"
						  inputProps={{ 'aria-label': 'primary checkbox' }}
        			/>
					<FormControlLabel
          				control={<Checkbox name="isDeveloper" type="checkbox" id="isDeveloper" autoComplete="off" checked={isDeveloper} onChange={e => setIsDeveloper(e.target.checked)}  />}
          				label="Developer"
						  labelPlacement="start"
						  inputProps={{ 'aria-label': 'primary checkbox' }}
        			/>
					<Button
						//disabled={isInvalid}
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						onClick={onRegister}
						className={classes.submit}>
						Register
          			</Button>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="secondary"
						component={Link}
						to={ROUTES.LOGIN}
						className={classes.submit}>
						Go back to Login
          			</Button>
	          		<Button
						type="submit"
						fullWidth
						variant="contained"
						color="secondary"
						component={Link}
						to={ROUTES.HOME}
						className={classes.submit}>
						HOME
	          		</Button>
				</form>
			</Paper>
		</main>
	)

	async function onRegister() {
		try {	 
			if (isAdmin) {
			  roles['ADMIN'] = ROLES.ADMIN;
			}
			if (isDeveloper) {
				roles['DEV'] = ROLES.DEVELOPER;
				menus["developers"] = ["api-keys"]
			}
			let email_verified = false
			let phone_number = null
			let photo_url = null
			let disabled = null
			let display_name =  name
			let authUserUID = await createAuthUser(email, email_verified, phone_number, password, display_name, photo_url, disabled)
			firebase.user(authUserUID).set({name, email, roles, menus})
			props.history.replace('/dashboard/default')
		} catch(error) {
			alert(error.message)
		}
	}
}

export default withRouter(withStyles(styles)(AdminRegister))