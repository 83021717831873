import React, { useState } from 'react';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";

const ModalComponent = (props) => {

  const [isOpen, setIsOpen] = React.useState(false);

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <button type="button" className="btn btn-primary" onClick={showModal}>Apply Assumptions</button>
      <Modal show={isOpen} onHide={hideModal}>
        <Modal.Header>
          <Modal.Title>Assumptions to be Used</Modal.Title>
        </Modal.Header>
        <Modal.Body>You are using the following assumptions:
          <ul>
            <li>Rate: {props.rate}%</li>
            <li>Downpayment: ${props.downpayment}%</li>
            <li>Insurance(annual): ${props.insurance}</li>
            <li>Closing Costs: {props.closingCosts}%</li>
            <li>Management Fee: {props.managementFee}%</li>
            <li>Closed Rents: {props.closedRents} days back</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-light" onClick={hideModal}>Cancel</button>
          <button type="button" className="btn btn-primary" onClick={() => { props.fetchData(); hideModal(); } }>Continue</button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalComponent;
