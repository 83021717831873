import React, { useState, useContext } from 'react';
import { Typography, Paper, Avatar, Button, FormControl, FormControlLabel, Input, InputLabel, Checkbox } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link, withRouter } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { FirebaseContext } from '../Firebase';
import { AuthUserContext, useFirebaseAuthentication } from '../Session';

const styles = theme => ({
	main: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
			width: 400,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginTop: theme.spacing.unit * 8,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
	},
	avatar: {
		margin: theme.spacing.unit,
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing.unit,
	},
	submit: {
		marginTop: theme.spacing.unit * 3,
	},
})

function Register(props) {
	const condition = authUser => !!authUser;
	const firebase = useContext(FirebaseContext);
	const authUser = useFirebaseAuthentication(firebase);
	const { classes } = props
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [error, setError] = useState(null)
	const isInvalid =
      password !== confirmPassword ||
      password === '' ||
      email === '' ||
      name === '';

	return (
		<main className={classes.main}>
			<Paper className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Register Account
       			</Typography>
				<form className={classes.form} onSubmit={e => e.preventDefault() && false }>
					<FormControl margin="normal" required fullWidth>
						<InputLabel htmlFor="name">Name</InputLabel>
						<Input id="name" name="name" autoComplete="off" autoFocus value={name} onChange={e => setName(e.target.value)} />
					</FormControl>
					<FormControl margin="normal" required fullWidth>
						<InputLabel htmlFor="email">Email Address</InputLabel>
						<Input id="email" name="email" autoComplete="off" value={email} onChange={e => setEmail(e.target.value)}  />
					</FormControl>
					<FormControl margin="normal" required fullWidth>
						<InputLabel htmlFor="password">Password</InputLabel>
						<Input name="password" type="password" id="password" autoComplete="off" value={password} onChange={e => setPassword(e.target.value)}  />
					</FormControl>
					<FormControl margin="normal" required fullWidth>
						<InputLabel htmlFor="confimrPassword">Confirm Password</InputLabel>
						<Input name="confirmPassword" type="password" id="confirmPassword" autoComplete="off" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)}  />
					</FormControl>
					<Button
						//disabled={isInvalid}
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						onClick={onRegister}
						className={classes.submit}>
						Register
          			</Button>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="secondary"
						component={Link}
						to={ROUTES.LOGIN}
						className={classes.submit}>
						Go back to Login
          			</Button>
	          		<Button
						type="submit"
						fullWidth
						variant="contained"
						color="secondary"
						component={Link}
						to={ROUTES.HOME}
						className={classes.submit}>
						HOME
	          		</Button>
				</form>
			</Paper>
		</main>
	)

	async function onRegister() {
		try {	 
			await firebase.doCreateUserWithEmailAndPassword(email, password)
			await firebase.doDisplayNameUpdate(name)
			let authUser = firebase.auth.currentUser
			firebase.user(authUser.uid).set({name, email})
			props.history.replace('/dashboard/default')
		} catch(error) {
			alert(error.message)
		}
	}

}

export default withRouter(withStyles(styles)(Register))