import React, { useState, useContext, useEffect, createContext } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import routes from "./route";
import * as ROUTES from "./constants/routes"
import config from './config';
import './styles.css'
import HomePage from './components/HomePage'
import Login from './components/Login'
import SignInWithEmailLink from './components/LoginWithEmailLink'
import Register from './components/Register'
import AdminRegister from './components/AdminRegister'
import PasswordForgotPage from './components/PasswordForgot'
import PasswordChange from './components/PasswordChange'
import PasswordCreate from './components/PasswordCreate'
import AccountPage from './components/Account'
import AdminPage from './components/Admin'
import AdminLayout from './App/layout/AdminLayout'
import RestrictedPage from './components/Restricted'
import TabulatorTable from './products/2to4UnitAnalyzer/TabulatorTable'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { CssBaseline, CircularProgress } from '@material-ui/core'

import { AuthProvider } from './Auth';

const theme = createMuiTheme()

const App = (props) => {

  //const user = { name: 'Rin Tin', loggedIn: true }
 
  const menu = routes.map((route, index) => {
    return (route.component) ? (
        <Route
            key={index}
            path={route.path}
            exact={route.exact}
            name={route.name}
            render={props => (
                <route.component {...props} />
            )} />
    ) : (null);
  });
 
  return (
    /*<UserProvider value={user}>*/
    <AuthProvider>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
          <Router basename={config.basename}>
            <Switch>
                {menu}
                <Route exact path={ROUTES.RESTRICTED} component={RestrictedPage} />
                <Route exact path={ROUTES.HOME} component={HomePage} />
                <Route exact path={ROUTES.LOGIN} component={Login} />
                <Route exact path={ROUTES.LOGINWITHEMAILLINK} component={SignInWithEmailLink} />
                <Route exact path={ROUTES.REGISTER} component={Register} />
                <Route exact path={ROUTES.ADMINREGISTER} component={AdminRegister} />
                {/*<Route exact path={ROUTES.DASHBOARD} component={AdminLayout} />*/}
                <Route exact path={ROUTES.DASHBOARD} render={(props) => <AdminLayout {...props} rate={'4.50'} downpayment={'25'} 
                            insurance={'1500'}
                            closingCosts={'2.0'}
                            managementFee={'10'}
                            closedRents={'360'}
                />} />                
                <Route exact path={ROUTES.PASSWORD_FORGOT} component={PasswordForgotPage} />
                <Route exact path={ROUTES.PASSWORD_UPDATE} component={PasswordChange} />
                <Route exact path={ROUTES.PASSWORD_CREATE} component={PasswordCreate} />
                <Route exact path={ROUTES.ACCOUNT} component={AccountPage} />
                <Route exact path={ROUTES.ADMIN} component={AdminPage} />
                {/*<Route exact path={ROUTES.TWOFOURUNITANALYZER} component={AdminLayout} />*/}
                <Route exact path={ROUTES.TWOFOURUNITANALYZER} render={(props) => <AdminLayout {...props} rate={'4.50'} downpayment={'25'} 
                            insurance={'1500'}
                            closingCosts={'2.0'}
                            managementFee={'10'}
                            closedRents={'360'}
                />} />
                <Route exact path={ROUTES.TWOFOURUNITANALYZERCASESTUDY} component={TabulatorTable} />
                <Route exact path={ROUTES.APIFORM} component={AdminLayout} />
                <Route exact path={ROUTES.BASICFORMS} component={AdminLayout} />
                <Route component={HomePage} />
            </Switch>
          </Router>
      </MuiThemeProvider>
    </AuthProvider>
    /*</UserProvider>*/
  ) 
}

export default App;